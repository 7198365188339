<template>
  <div class="w-full h-full pt-5">
    <template>
      <div class="flex mb-10 px-5">
        <h1 class="text-xl text-left font-extrabold mr-5">
          Overview
        </h1>
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </template>
    <div v-if="!showError">
    <template v-if="isLoading">
       <div style="height:100%">
        <Loader size="xxs" :loader-image="false" />
      </div>
    </template>
    <template v-else>
      <div class="flex flex-col gap-5">
        <template>
          <template v-if="isLoading">
            <div style="height:100%">
              <Loader size="xxs" :loader-image="false" />
            </div>
          </template>
          <template v-else>
            <div class="flex flex-row justify-evenly gap-10 mx-5 mb-5">
              <Card class="w-1/2 px-5 py-5 flex justify-start items-center gap-2" style="background:#321c3b">
                <Icon icon-name="employee_payrun" class-name="text-white" size="l" />
                <div class="flex flex-col justify-start items-start">
                  <p class="font-semibold uppercase  text-xs text-white">Total Employees</p>
                  <p class="font-black text-4xl text-white">{{ totalEmployees || 0 }}</p>
                </div>
              </Card>
              <template v-if="employmentPayTypes.salaried">
                <Card class="w-1/2 px-5 py-5 flex justify-start items-center gap-5" style="background:#3454d1">
                  <div class="">
                    <div class="flex flex-row justify-start gap-2 text-white">
                      <Icon icon-name="wallet" class-name="text-white" size="l" />
                      <div class="flex flex-col items-start gap-0">
                        <p class="text-xs uppercase font-semibold">Employee Pay Type</p>
                        <p class="text-2xl uppercase font-bold">Salaried</p>
                      </div>
                    </div>
                    <div class="flex flex-col justify-start">
                      <div class="flex justify-start items-center gap-2">
                        <p class="font-extrabold text-4xl text-white">
                          {{ employmentPayTypes.salaried }}
                        </p>
                        <div class="rounded"
                          :class="employmentPayTypes.salaried < 0 ? 'bg-red-100 text-flame' :
                          employmentPayTypes.salaried > 0 ? 'bg-green-100 text-mediumSeaGreen' :
                          'bg-gray-100 text-romanSilver'"
                        >
                          <div class="p-1 flex justify-start items-center gap-2">
                            <Icon icon-name="icon-dashed" size="xs" v-if="employmentPayTypes.salaried === 0" />
                            <Icon icon-name="icon-trending-down" size="xs" v-if="employmentPayTypes.salaried < 0" />
                            <Icon icon-name="icon-trending-up-green" size="xs" v-if="employmentPayTypes.salaried > 0" />
                            <p class="font-semibold text-xs uppercase">{{ employmentPayTypes.salaried }}%</p>
                          </div>
                        </div>
                        <p class="text-xs text-white text-semibold">(Month by Month)</p>
                      </div>
                    </div>
                  </div>
                </Card>
              </template>
              <template v-if="employmentPayTypes.hourly">
                <Card class="w-1/2 px-5 py-5 flex justify-start items-center gap-5" style="background:#13b56a">
                  <div class="">
                    <div class="flex flex-row justify-start gap-2 text-white">
                      <Icon icon-name="wallet" class-name="text-white" size="l" />
                      <div class="flex flex-col items-start gap-0">
                        <p class="text-xs uppercase font-semibold">Employee Pay Type</p>
                        <p class="text-2xl uppercase font-bold">HOURLY</p>
                      </div>
                    </div>
                    <div class="flex flex-col justify-start">
                      <div class="flex justify-start items-center gap-2">
                        <p class="font-extrabold text-4xl text-white">
                          {{ employmentPayTypes.hourly }}
                        </p>
                        <div class="rounded"
                          :class="employmentPayTypes.hourly < 0 ? 'bg-red-100 text-flame' :
                          employmentPayTypes.hourly > 0 ? 'bg-green-100 text-mediumSeaGreen' :
                          'bg-gray-100 text-romanSilver'"
                        >
                          <div class="p-1 flex justify-start items-center gap-2">
                            <Icon icon-name="icon-dashed" size="xs" v-if="employmentPayTypes.hourly === 0" />
                            <Icon icon-name="icon-trending-down" size="xs" v-if="employmentPayTypes.hourly < 0" />
                            <Icon icon-name="icon-trending-up-green" size="xs" v-if="employmentPayTypes.hourly > 0" />
                            <p class="font-semibold text-xs uppercase">{{ employmentPayTypes.hourly }}%</p>
                          </div>
                        </div>
                        <p class="text-xs text-white text-semibold">(Month by Month)</p>
                      </div>
                    </div>
                  </div>
                </Card>
              </template>
            </div>
            <!-- <Card class="p-2 mx-5">
              <div class="flex flex-col justify-start px-5 py-2 bg-gray-50 rounded gap-1">
                <h2 class="font-bold text-lg text-darkPurple">Employee Pay Type</h2>
                <div class="flex justify-between items-center divide-x divide-dashed divide-romanSilver">
                  <div class="w-1/2 flex flex-col justify-start gap-0" v-if="employmentPayTypes.hourly">
                    <h3 class="font-bold text-xl text-blueCrayola">Hourly</h3>
                    <div class="flex justify-start items-center gap-2">
                      <p class="font-extrabold text-2xl text-darkPurple">
                        {{ employmentPayTypes.hourly }}
                      </p>
                      <div class="rounded"
                      :class="employmentPayTypes.hourly < 0 ? 'bg-red-100 text-flame' :
                      employmentPayTypes.hourly > 0 ? 'bg-green-100 text-mediumSeaGreen' :
                      'bg-gray-100 text-romanSilver'"
                    >
                      <div class="p-1 flex justify-start items-center gap-2">
                        <Icon icon-name="icon-dashed" size="xs" v-if="employmentPayTypes.hourly === 0" />
                        <Icon icon-name="icon-trending-down" size="xs" v-if="employmentPayTypes.hourly < 0" />
                        <Icon icon-name="icon-trending-up-green" size="xs" v-if="employmentPayTypes.hourly > 0" />
                        <p class="font-semibold text-xs uppercase">{{ employmentPayTypes.hourly }}%</p>
                      </div>
                    </div>
                      <p class="text-xs text-jet text-normal">(Month by Month)</p>
                    </div>
                  </div>
                  <div class="w-1/2 flex flex-col justify-start pl-5 gap-0" v-if="employmentPayTypes.salaried">
                    <h3 class="font-bold text-xl text-blueCrayola">Salaried</h3>
                    <div class="flex justify-start items-center gap-2">
                      <p class="font-extrabold text-2xl text-darkPurple">
                        {{ employmentPayTypes.salaried }}
                      </p>
                      <div class="rounded"
                        :class="employmentPayTypes.salaried < 0 ? 'bg-red-100 text-flame' :
                        employmentPayTypes.salaried > 0 ? 'bg-green-100 text-mediumSeaGreen' :
                        'bg-gray-100 text-romanSilver'"
                      >
                        <div class="p-1 flex justify-start items-center gap-2">
                          <Icon icon-name="icon-dashed" size="xs" v-if="employmentPayTypes.salaried === 0" />
                          <Icon icon-name="icon-trending-down" size="xs" v-if="employmentPayTypes.salaried < 0" />
                          <Icon icon-name="icon-trending-up-green" size="xs" v-if="employmentPayTypes.salaried > 0" />
                          <p class="font-semibold text-xs uppercase">{{ employmentPayTypes.salaried }}%</p>
                        </div>
                      </div>
                      <p class="text-xs text-jet text-semibold">(Month by Month)</p>
                    </div>
                  </div>
                </div>
              </div>
            </Card> -->
          </template>
        </template>
        <template>
          <div class="mx-5 rounded-md flex justify-between gap-5">
            <Card class="flex flex-col justify-start items-start p-5 gap-5 w-3/5">
              <div class="w-full flex flex-col gap-3 relative">
                <div class="flex justify-between items-center">
                  <div class="flex flex-col justify-start items-start gap-0">
                    <p class="font-bold text-darkPurple text-lg">Location Distribution</p>
                    <p class="font-normal text-jet text-sm">Organization distribution by location.</p>
                  </div>
                  <!-- <div class="bg-flame rounded pr-2 flex items-center text-base font-normal text-darkPurple h-8 w-20">
                    <template v-if="locationOptions.length">
                      <Select
                        variant="rm-border text-white color-white flex items-center"
                        placeholder="Top 5"
                        v-model="locationDistributionId"
                        :options="locationOptions.slice(0, 5)"
                        @input="getLocationDistributions({ locationId: $event })"
                      />
                    </template>
                  </div> -->
                </div>
                <template>
                  <template v-if="locationOptions.length">
                    <!-- <div class="flex justify-between gap-5 relative">
                      <Card class="w-3/5 h-11 flex items-center text-base text-darkPurple">
                        <Select
                          class="w-full select-css"
                          variant="rm-border"
                          placeholder="All or Location"
                          v-model="locationDistributionId"
                          :options="['All Locations', ...locationOptions]"
                           @input="getLocationDistributions({ locationId: $event })"
                        />
                      </Card>
                      <Card class="flex justify-evenly items-center gap-1 px-5 w-2/5 relative">
                        <div class="w-1/7 pr-2 border-r border-darkPurple">
                          <Icon icon-name="calendar" size="xs" />
                        </div>
                        <div class="w-5/7 flex">
                          <Datepicker placeholder="Year to Date" input-class="w-32 pl-2" />
                        </div>
                        <div class="w-1/7">
                          <Icon icon-name="caret-down" size="xs" />
                        </div>
                      </Card>
                    </div> -->
                    <template v-if="locationIsLoading">
                      <div style="height:300px">
                        <Loader size="xxs" :loader-image="false" />
                      </div>
                    </template>
                    <template v-else>
                      <div class="relative" style="width:100%;height:100%;">
                        <GoogleMap
                          styles="width:100%;height:400px"
                          :locations="locationDistribution"
                        />
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <div class="flex flex-col text-center mb-3">
                      <Icon
                        icon-name="dashboard-error"
                        class="-mt-1 iconSize"
                        style="width: 350px; height: 350px; margin: 0 auto"
                      />
                      <p class="text-xl font-bold">No Data Available</p>
                      <p class="text-xs text-romanSilver">There is no data to show currently.</p>
                    </div>
                  </template>
                </template>
              </div>
            </Card>
            <Card class="w-2/5 flex flex-col p-5 gap-7">
              <div class="text-darkPurple flex justify-between w-full">
                <div>
                  <p class="font-bold text-xl w-1/2">Locations</p>
                  <p class="font-normal text-sm text-romanSilver">All Continents</p>
                </div>
                <p class="font-extrabold text-5xl">{{ locationOptions.length }}</p>
              </div>
              <template>
                <template v-if="locationOptions.length">
                  <div class="bg-gray-50 rounded-md p-2 flex flex-col gap-8 relative overflow-y-auto h-96">
                    <div v-for="location in locationOptions" :key="location.id">
                      <div class="flex justify-between items-center gap-2">
                        <div class="flex justify-start items-start gap-2">
                          <div class="w-8 h-8 rounded-md" v-if="location.photo">
                            <img :src="location.photo" class="w-10 h-10" />
                          </div>
                          <div v-else class="border border-blueCrayola rounded-md w-10 h-10 flex justify-center">
                            <div class="px-5 flex justify-center items-center font-bold">
                              {{ $getInitials(location.name) }}
                            </div>
                          </div>
                          <div class="flex flex-col justify-start items-start gap-0">
                            <span class="font-semibold text-base text-darkPurple capitalize">
                              {{ location.name }}
                            </span>
                            <span class="text-xs text-romanSilver font-normal uppercase">
                              {{ location.address }}
                            </span>
                          </div>
                        </div>
                        <p class="text-romanSilver text-base font-normal whitespace-nowrap">
                          {{ location.employeesCount }} Employees</p>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="flex flex-col w-full mb-5 items-center">
                    <Icon
                      icon-name="dashboard-error"
                      class="-mt-1 iconSize"
                      style="width:350px;height:350px;margin:0 auto"
                    />
                    <p class="text-xl font-bold">No Data Available</p>
                    <p class="text-xs text-romanSilver">There is no data to show currently.</p>
                  </div>
                </template>
              </template>
            </Card>
          </div>
        </template>
        <template>
          <div class="mx-5 flex justify-between gap-5">
            <Card class="p-5 flex flex-col justify-between items-start gap-5 w-full">
              <div class="w-full flex flex-col gap-3">
                <div class="flex justify-between items-center">
                  <div class="flex flex-col justify-start items-start gap-0">
                    <p class="font-bold text-darkPurple text-lg">Functions</p>
                  </div>
                  <!-- <div class="px-3 flex items-center text-base font-normal text-darkPurple h-8">
                    <Select
                      variant="rm-border flex items-center w-20"
                      class="w-20"
                      placeholder="Top 3"
                      v-model="functionDistributionId"
                      :options="functionOptions.slice(0,3)"
                      @input="getFunctionDistributions({ functionId: $event })"
                    />
                  </div> -->
                </div>
                <template>
                  <template v-if="functionChartData.labels.length">
                    <div class="flex flex-col justify-start gap-2">
                      <div class="flex justify-between gap-5">
                        <Card class="w-3/5 h-11 flex items-center text-base text-darkPurple">
                          <Select
                            class="w-full select-css"
                            variant="rm-border"
                            placeholder="All or Functions"
                            v-model="functionDistributionId"
                            :options="['All Functions',...functionOptions]"
                            @input="getFunctionDistributions({ functionId: $event })"
                          />
                        </Card>
                        <Card class="flex justify-evenly items-center gap-1 px-5 w-2/5">
                          <div class="w-1/7 pr-2 border-r border-darkPurple">
                            <Icon icon-name="calendar" size="xs" />
                          </div>
                          <div class="w-5/7 flex">
                            <Datepicker placeholder="Year to Date" input-class="w-28 pl-2" />
                          </div>
                          <div class="w-1/7">
                            <Icon icon-name="caret-down" size="xs" />
                          </div>
                        </Card>
                      </div>
                      <template v-if="functionIsLoading">
                        <div style="height:300px">
                          <Loader size="xxs" :loader-image="false" />
                        </div>
                      </template>
                      <template v-else>
                        <div class="flex flex-col justify-start h-full">
                          <div class="flex flex-row justify-between items-center gap-1 my-2">
                            <div class="flex flex-row items-center gap-1">
                              <div class="p-2 h-2 w-2 rounded-sm bg-darkPurple" />
                              <p class="text-base font-semibold text-darkPurple uppercase">No. of Employees</p>
                            </div>
                            <p class="text-4xl font-extrabold text-darkPurple">{{ totalEmployees }}</p>
                          </div>
                          <div class="flex justify-center items-start">
                            <DoughnutChart
                              :options="functionChartOptions"
                              :data="functionChartData"
                              :styles="{height: '400px', width: '300px', position: 'relative'}"
                            />
                          </div>
                        </div>
                      </template>
                    </div>
                  </template>
                  <template v-else>
                    <div class="flex flex-col text-center mb-3">
                      <Icon
                        icon-name="dashboard-error"
                        class="-mt-1 iconSize"
                        style="width:350px;height:350px;margin:0 auto"
                      />
                      <p class="text-xl font-bold">No Data Available</p>
                      <p class="text-xs text-romanSilver">There is no data to show currently.</p>
                    </div>
                  </template>
                </template>
              </div>
            </Card>
            <Card class="p-5 flex flex-col justify-between items-start gap-5 w-full">
              <div class="w-full flex flex-col gap-3">
                <div class="flex justify-between items-center">
                  <div class="flex flex-col justify-start items-start gap-0">
                    <p class="font-bold text-darkPurple text-lg">Gender</p>
                  </div>
                  <!-- <div class="px-3 flex items-center text-base font-normal text-darkPurple h-8">
                    <Select
                      variant="rm-border flex items-center w-20"
                      class="w-20"
                      placeholder="Top 3"
                      v-model="genderDistributionId"
                      :options="functionOptions.slice(0,3)"
                      @input="getGenderDistributions({ functionId: $event })"
                    />
                  </div> -->
                </div>
                <template>
                  <template v-if="genderChartData.labels.length">
                    <div class="flex flex-col justify-start gap-2">
                      <div class="flex justify-between gap-5">
                        <Card class="w-3/5 h-11 flex items-center text-base text-darkPurple">
                          <Select
                            class="w-full select-css"
                            variant="rm-border"
                            placeholder="All or Functions"
                            v-model="genderDistributionId"
                            :options="['All Functions',...functionOptions]"
                            @input="getGenderDistributions({ functionId: $event })"
                          />
                        </Card>
                        <Card class="flex justify-evenly items-center gap-1 px-5 w-2/5">
                          <div class="w-1/7 pr-2 border-r border-darkPurple">
                            <Icon icon-name="calendar" size="xs" />
                          </div>
                          <div class="w-5/7 flex">
                            <Datepicker placeholder="Year to Date" input-class="w-28 pl-2" />
                          </div>
                          <div class="w-1/7">
                            <Icon icon-name="caret-down" size="xs" />
                          </div>
                        </Card>
                      </div>
                      <template v-if="genderIsLoading">
                        <div style="height:300px">
                          <Loader size="xxs" :loader-image="false" />
                        </div>
                      </template>
                      <template v-else>
                        <div class="flex flex-col justify-start">
                          <div class="flex flex-row justify-between items-center gap-1 my-2">
                            <div class="flex flex-row items-center gap-1">
                              <div class="p-2 h-2 w-2 rounded-sm bg-darkPurple" />
                              <p class="text-base font-semibold text-darkPurple uppercase">No. of Employees</p>
                            </div>
                            <p class="text-4xl font-extrabold text-darkPurple">{{ totalGenderCount }}</p>
                          </div>
                          <div class="flex justify-center items-start">
                            <DoughnutChart
                              :options="genderChartOptions"
                              :data="genderChartData"
                              :styles="{height: '400px', width: '300px', position: 'relative'}"
                            />
                          </div>
                        </div>
                      </template>
                    </div>
                  </template>
                  <template v-else>
                    <div class="flex flex-col text-center mb-3">
                      <Icon
                        icon-name="dashboard-error"
                        class="-mt-1 iconSize"
                        style="width:350px;height:350px;margin:0 auto"
                      />
                      <p class="text-xl font-bold">No Data Available</p>
                      <p class="text-xs text-romanSilver">There is no data to show currently.</p>
                    </div>
                  </template>
                </template>
              </div>
            </Card>
          </div>
        </template>
        <template>
          <div class="mx-5 rounded-md flex justify-between gap-5">
            <Card class="p-5 flex flex-col justify-between items-start gap-5 w-full">
              <div class="w-full flex flex-col gap-3">
                <div class="flex justify-between items-center">
                  <div class="flex flex-col justify-start items-start gap-0">
                    <p class="font-bold text-darkPurple text-lg">Employment Types</p>
                  </div>
                  <!-- <div class="px-3 flex items-center text-base font-normal text-darkPurple h-8">
                    <Select
                      variant="rm-border flex items-center w-20"
                      class="w-20"
                      placeholder="Top 3"
                      v-model="employmentTypeDistributionId"
                      :options="functionOptions.slice(0,3)"
                      @input="getEmploymentTypeDistributions({ functionId: $event })"
                    />
                  </div> -->
                </div>
                <template>
                  <template v-if="employmentChartData.labels.length">
                    <div class="flex flex-col justify-start gap-2">
                      <div class="flex justify-between">
                        <Card class="w-1/2 h-11 flex items-center text-base text-darkPurple">
                          <Select
                            class="w-full select-css"
                            variant="rm-border"
                            placeholder="All or Functions"
                            v-model="employmentTypeDistributionId"
                            :options="['All Functions',...functionOptions]"
                            @input="getEmploymentTypeDistributions({ functionId: $event })"
                          />
                        </Card>
                        <Card class="flex justify-evenly items-center gap-1 px-5 w-2/5">
                          <div class="w-1/7 pr-2 border-r border-darkPurple">
                            <Icon icon-name="calendar" size="xs" />
                          </div>
                          <div class="w-5/7 flex">
                            <Datepicker placeholder="Year to Date" input-class="w-28 pl-2" />
                          </div>
                          <div class="w-1/7">
                            <Icon icon-name="caret-down" size="xs" />
                          </div>
                        </Card>
                      </div>
                      <template v-if="employmentTypeIsLoading">
                        <div style="height:300px">
                          <Loader size="xxs" :loader-image="false" />
                        </div>
                      </template>
                      <template v-else>
                        <div class="flex flex-col justify-between">
                          <div class="flex flex-row justify-between items-center gap-1 my-2">
                            <div class="flex flex-row items-center gap-1">
                              <div class="p-2 h-2 w-2 rounded-sm bg-darkPurple" />
                              <p class="text-base font-semibold text-darkPurple uppercase">No. of Employees</p>
                            </div>
                            <p class="text-4xl font-extrabold text-darkPurple">{{ totalEmploymentTypes }}</p>
                          </div>
                          <div class="flex flex-col justify-center items-center py-5 px-2">
                            <HorizontalBarChart
                              :options="employmentChartOptions"
                              :chart-data="employmentChartData"
                              :data="employmentChartData"
                              :styles="{height: '300px', width: '420px', position: 'relative'}"
                            />
                            <div class="flex flex-wrap justify-center items-center gap-2">
                              <div class="flex justify-center items-center gap-1"
                                v-for="legend in employmentChartData.legends" :key="legend.label">
                                <div class="rounded-full w-3 h-3" :style="`background: ${legend.bgColor}`" />
                                <p class="text-xs text-jet">{{ legend.label }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </template>
                  <template v-else>
                    <div class="flex flex-col text-center mb-3">
                      <Icon
                        icon-name="dashboard-error"
                        class="-mt-1 iconSize"
                        style="width:350px;height:350px;margin:0 auto"
                      />
                      <p class="text-xl font-bold">No Data Available</p>
                      <p class="text-xs text-romanSilver">There is no data to show currently.</p>
                    </div>
                  </template>
                </template>
              </div>
            </Card>
            <Card class="p-5 flex flex-col justify-between items-start gap-5 w-full">
              <div class="w-full flex flex-col gap-3">
                <div class="flex justify-between items-center">
                  <div class="flex flex-col justify-start items-start gap-0">
                    <p class="font-bold text-darkPurple text-lg">Employment Status</p>
                  </div>
                  <!-- <div class="px-3 flex items-center text-base font-normal text-darkPurple h-8">
                    <Select
                      variant="rm-border flex items-center w-20"
                      class="w-20"
                      placeholder="Top 3"
                      v-model="employmentStatusDistributionId"
                      :options="functionOptions.slice(0,3)"
                      @input="getEmploymentStatusDistributions({ functionId: $event })"
                    />
                  </div> -->
                </div>
                <template>
                  <template v-if="employmentStatusChartData.labels.length">
                    <div class="flex flex-col justify-start gap-2">
                      <div class="flex justify-between">
                        <Card class="w-1/2 h-11 flex items-center text-base text-darkPurple">
                          <Select
                            class="w-full select-css"
                            variant="rm-border"
                            placeholder="All or Functions"
                            v-model="employmentStatusDistributionId"
                            :options="['All Functions',...functionOptions]"
                            @input="getEmploymentStatusDistributions({ functionId: $event })"
                          />
                        </Card>
                        <Card class="flex justify-evenly items-center gap-1 px-5 w-2/5">
                          <div class="w-1/7 pr-2 border-r border-darkPurple">
                            <Icon icon-name="calendar" size="xs" />
                          </div>
                          <div class="w-5/7 flex">
                            <Datepicker placeholder="Year to Date" input-class="w-28 pl-2" />
                          </div>
                          <div class="w-1/7">
                            <Icon icon-name="caret-down" size="xs" />
                          </div>
                        </Card>
                      </div>
                      <template v-if="employmentStatusIsLoading">
                        <div style="height:300px">
                          <Loader size="xxs" :loader-image="false" />
                        </div>
                      </template>
                      <template v-else>
                        <div class="flex flex-col justify-between">
                          <div class="flex flex-row justify-between items-center gap-1 my-2">
                            <div class="flex flex-row items-center gap-1">
                              <div class="p-2 h-2 w-2 rounded-sm bg-darkPurple" />
                              <p class="text-base font-semibold text-darkPurple uppercase">No. of Employees</p>
                            </div>
                            <p class="text-4xl font-extrabold text-darkPurple">{{ totalEmploymentStatus }}</p>
                          </div>
                          <div class="flex flex-col justify-center items-center py-5 px-2">
                            <HorizontalBarChart
                              :options="employmentStatusChartOptions"
                              :chart-data="employmentStatusChartData"
                              :data="employmentStatusChartData"
                              :styles="{height: '300px', width: '420px', position: 'relative'}"
                            />
                            <div class="flex flex-wrap justify-center items-center gap-2">
                              <div class="flex justify-center items-center gap-1"
                                v-for="legend in employmentStatusChartData.legends" :key="legend.label">
                                <div class="rounded-full w-3 h-3" :style="`background: ${legend.bgColor}`" />
                                <p class="text-xs text-jet">{{ legend.label }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </template>
                  <template v-else>
                    <div class="flex flex-col text-center mb-3">
                      <Icon
                        icon-name="dashboard-error"
                        class="-mt-1 iconSize"
                        style="width:350px;height:350px;margin:0 auto"
                      />
                      <p class="text-xl font-bold">No Data Available</p>
                      <p class="text-xs text-romanSilver">There is no data to show currently.</p>
                    </div>
                  </template>
                </template>
              </div>
            </Card>
          </div>
        </template>
      </div>
    </template>
    </div>
    <div class="flex justify-center items-center" v-else>
      <error-page />
    </div>
  </div>
</template>

<script>
  import * as _ from 'lodash'
  import Datepicker from "vuejs-datepicker"
  import Gradient from "javascript-color-gradient"
  import Loader from "@scelloo/cloudenly-ui/src/components/loader";
  import ErrorPage from "@/modules/Admin/error403";
  import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
  import Select from "@scelloo/cloudenly-ui/src/components/select";
  import Card from "@scelloo/cloudenly-ui/src/components/card"
  import DoughnutChart from "@/components/donutChart"
  import HorizontalBarChart from "@/components/HorizontalBarChart"
  import Icon from "@/components/Icon"
  import GoogleMap from "@/components/GoogleMap"

  export default {
    components: {
      Loader,
      Datepicker,
      Breadcrumb,
      ErrorPage,
      HorizontalBarChart,
      DoughnutChart,
      Select,
      Card,
      Icon,
      GoogleMap
    },
    data(){
      return {
        isLoading: true,
        mapIsLoading: false,
        PayTypesIsLoading: true,
        locationIsLoading: true,
        functionIsLoading: true,
        showError: false,
        genderIsLoading: true,
        employmentTypeIsLoading: true,
        employmentStatusIsLoading: true,
        totalEmployees: '',
        locationOptions: [],
        locationDistribution: [],
        locationDistributionId: '',
        functionDistributionId: '',
        genderDistributionId: '',
        employmentTypeDistributionId: '',
        employmentStatusDistributionId: '',
        totalGenderCount: '',
        totalEmploymentTypes: '',
        totalEmploymentStatus: '',
        employeeGenderInfo: {},
        employmentPayTypes: {},
        functionOptions: [],
        payTypes: {},
        genderChartData: {
          labels: ['Gender'],
          datasets: [{backgroundColor: ['#878E99'], data: [100]}]
        },
        genderChartOptions: {
          plugins: {
            labels: {
              render: 'percentage',
              fontColor: 'red',
              position: 'outside'
            }
          },
          legend: {
            display: true,
            position: "bottom",
            labels: {
              usePointStyle: true,
              fontSize: 10,
              fontColor: '#321C3B'
            },
          },
          maintainAspectRatio: false,
          responsive: true,
          cutoutPercentage: 60,
        },
        functionChartData: {
          labels: ['Functions'],
          datasets: [{data: [100], backgroundColor: ['#878E99']}]
        },
        functionChartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          cutoutPercentage: 60,
          plugins: {
            labels: {
              render: 'percentage',
              fontColor: 'red',
              position: 'outside'
            }
          },
          legend: {
            display: true,
            position: "bottom",
            labels: {
              usePointStyle: true,
              fontSize: 10,
              fontColor: '#321C3B'
            },
          },
        },
        employmentChartData: {
          legends: [],
          labels: ['Employment'],
          datasets: [{data: [100], backgroundColor: ['#878E99']}]
        },
        employmentChartOptions: {
          legend: {
            display: false,
            position: "bottom",
            labels: {
              usePointStyle: true,
              fontSize: 9,
              fontColor: '#333333'
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
                color: '#878E99',
                borderDash: [4, 4],
              },
            }],
            yAxes: [{
              gridLines: {
                display: false,
              }
            }]
          },
        },
        employmentStatusChartData: {
          labels: ['Employment'],
          datasets: [{data: [100], backgroundColor: ['#878E99']}]
        },
        employmentStatusChartOptions: {
           /* eslint-disable no-param-reassign */
          legend: {
            display: false,
            position: "bottom",
            labels: {
              usePointStyle: true,
              fontSize: 9,
              fontColor: '#333333'
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              ticks: {
                beginAtZero: true,
              },
              scaleLabel: {
                display: false,
                labelString: 'Total No of Employees'
              },
              gridLines: {
                display: true,
                color: '#878E99',
                borderDash: [4, 4],
              },
            }],
            yAxes: [{
              scaleLabel: {
                display: false,
                labelString: 'Employment Type'
              },
              gridLines: {
                display: false,
              }
            }]
          },
        },
        breadcrumbs: [
          {
          disabled: false,
          text: "Employees",
          href: "employees",
          id: "AnalyticsEmployees"
          },
          {
          disabled: false,
          text: "Overview",
          href: "overview",
          id: "EmployeesOverview"
          }
        ],
      }
    },
    methods: {
      async getEmploymentPayTypeDistribution(){
        await this.$_getOrgEmployeePayTypeDistribution().then(({ data }) => {
          this.employmentPayTypes = data.data
          this.PayTypesIsLoading = false
        })
      },
      getLocationDistributions({ locationId }){
        if(locationId === 'All Locations') locationId = ''
        this.locationIsLoading = true
          this.$_getOrgLocationDistribution({ locationId }).then(({ data }) => {
          this.locationDistribution = data.location
          this.locationIsLoading = false
          })
      },
      getFunctionDistributions({ functionId }){
        this.functionIsLoading = true
        if(functionId === 'All Functions') functionId =  ''
        this.$_getOrgFunctionDistribution({ functionId }).then(({ data }) => {
          const functionLabels = data.data?.map($el => $el.name)
          const employeeCount = _.map(data.data, 'employeeCount')
          this.totalEmployees = _.sum(employeeCount)
          const gradientArray = new Gradient().setColorGradient(
            "#13B56A", "#2176FF", "#EA3C53", "#878E99", "#F15A29", "#00D5E0"
          ).setMidpoint(this.totalEmployees).getColors()
          this.functionChartData = {
            datasets: [{ data: employeeCount, backgroundColor: gradientArray, }],
            labels: functionLabels,
          }
          this.functionIsLoading = false
        })
      },
      getGenderDistributions({ functionId }){
        this.genderIsLoading = true
        if(functionId === 'All Functions') functionId =  ''
        this.$_getOrgGenderDistribution({ functionId }).then(({ data }) => {
          const {maleCount, femaleCount, unspecifiedCount} = data.data
          this.totalGenderCount = _.sum([maleCount, femaleCount, unspecifiedCount])
          this.employeeGenderInfo = {maleCount, femaleCount, unspecifiedCount}
          this.genderChartData = {
            labels: ['Male', 'Female', 'Unspecified'],
            datasets: [{data: [maleCount, femaleCount, unspecifiedCount],
              backgroundColor: ['#13B56A', '#F15A29', '#3454d1']}
            ],
          }
          this.genderIsLoading = false
        })
      },
      getEmploymentTypeDistributions({ functionId }){
        this.employmentTypeIsLoading = true
        if(functionId === 'All Functions') functionId =  ''
        this.$_getOrgEmploymentDistribution({ functionId }).then(({ data }) => {
          const COLORS = ["#13B56A","#2176FF","#EA3C53","#878E99","#F15A29","#00D5E0"]
          const employmentsLabels = _.map(_.keys(data.data), label => _.startCase(label))
          const employmentsData = _.values(data.data)
          const legendsArray = _.map(employmentsLabels, (legend, i) => ({
            label: _.startCase(legend), bgColor: COLORS[i],
          }))
          this.employmentChartData = {
            labels: employmentsLabels, legends: legendsArray,
            datasets: [{data: employmentsData, backgroundColor: COLORS}]
          }
          this.totalEmploymentTypes = _.sum(_.values(data.data))
          this.employmentTypeIsLoading = false
        })
      },
      getEmploymentStatusDistributions({ functionId }){
        this.employmentStatusIsLoading = true
        if(functionId === 'All Functions') functionId =  ''
        this.$_getOrgEmploymentStatusDistribution({ functionId }).then(({ data }) => {
          const COLORS = ["#13B56A","#2176FF","#EA3C53","#878E99","#F15A29","#00D5E0"]
          const employmentStatusLabels = _.map(_.keys(data.data), label => _.startCase(label))
          const employmentStatusData = _.values(data.data)
          const legendsArray = _.map(employmentStatusLabels, (legend, i) => ({
            label: _.startCase(legend), bgColor: COLORS[i],
          }))
          this.employmentStatusChartData = {
            labels: employmentStatusLabels, legends: legendsArray,
            datasets: [{data: employmentStatusData, backgroundColor: COLORS}]
          }
          this.totalEmploymentStatus = _.sum(_.values(data.data))
          this.employmentStatusIsLoading = false
        })
      },
      async getOrgFunctions() {
        await this.$_getFunction().then(({ data }) => {
          this.functionOptions = data.functions.map(item => ({
            value: item.id, ...item
          }))
        })
      },
      async getOrgLocations() {
        await this.$_getHumanarLocations().then(({ data }) => {
          this.locationOptions = data.outlets.map(item => ({
            value: item.id, ...item
          }))
        })
      },
    },
    async mounted(){
      try {
        await this.$handlePrivilege("overviewEmpMgmt", "overviewEmpMgmt");
        this.showError = false
        this.isLoading = true
        this.getOrgFunctions()
        this.getOrgLocations()
        this.getEmploymentPayTypeDistribution()
        this.getLocationDistributions({})
        this.getFunctionDistributions({})
        this.getGenderDistributions({})
        this.getEmploymentTypeDistributions({})
        this.getEmploymentStatusDistributions({})
        this.isLoading = false
      } catch (error) {
        this.showError = true
      }
    }
  }
</script>
<style lang="scss" scoped>
  ::v-deep .rm-border {
    border: none !important;
  }
  ::v-deep .select-class {
    display: flex;
    justify-content: space-between;
  }
  ::v-deep .vdp-datepicker__calendar {
    margin-left: -100px !important;
    color: #321C3B !important;
  }
  ::v-deep .vdp-datepicker {
    color: #321C3B !important;
    input[type='text'] {
      color: #321C3B !important;
      &:hover, &:focus {
        outline: none !important;
        color: #321C3B !important;
      }
    }
  }
  ::v-deep .select-css span {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .inner-donut-function {
    display: flex;
    align-items:center;
    justify-content: center;
    position: relative;
    left: 232px;
    top: -35px;
  }
  .inner-donut-gender {
    display: flex;
    align-items:center;
    justify-content: center;
    position: relative;
    left: 212px;
    top: -15px;
  }
  .color-white{
    color: white !important;
  }
</style>
